/* stylelint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply bg-dark-navy text-slate leading-relaxed antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-lightest-slate;
  }
}

@layer components {
  .nav-indicator {
    @apply h-px w-8 bg-light-navy transition-all group-hover:w-16 group-hover:bg-gold;
  }

  .social-icon {
    @apply text-slate hover:text-gold transition-colors duration-200;
  }

  .external-link-icon {
    @apply inline-block h-4 w-4 ml-1 translate-y-px transition-transform group-hover:-translate-y-1 group-hover:translate-x-1;
  }

  .tech-tag {
    @apply mr-1.5 mt-2 rounded-full bg-gold/10 px-3 py-1 text-xs font-medium leading-5 text-gold;
  }

  .content-section {
    @apply mb-4 scroll-mt-12 md:mb-6 lg:mb-8 lg:scroll-mt-16;
  }

  .section-heading {
    @apply sticky top-0 z-20 -mx-6 mb-2 w-screen bg-dark-navy/75 px-6 py-2 backdrop-blur md:-mx-12 md:px-12 lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-100;
  }

  .section-title {
    @apply text-sm font-bold uppercase tracking-widest text-lightest-slate;
  }

  .title-link {
    @apply text-lightest-slate hover:text-gold transition-colors duration-200;
  }

  .sticky-section {
    @apply relative;
  }

  .sticky-section > div:first-child {
    @apply sticky top-0 z-20 -mx-6 bg-dark-navy/75 px-6 py-3 backdrop-blur md:-mx-12 md:px-12 lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:backdrop-blur-none;
  }
}

@keyframes skillBarSlide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
